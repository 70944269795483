'use strict';
var dandryer = dandryer || {};

dandryer.btnBar = dandryer.btnBar || function () {

    function init() {
        showOverlay();
        formSubmit();
    }
    
    function stickyBtnBar() {
        if($(window).width() >= 768) {
            var stickTopPosition = $('#js-site-header').outerHeight();
            if ($(window).scrollTop() > $('#btn-bar-placeholder').position().top - stickTopPosition) {
                $('#js-btn-bar').addClass("stick-to-top").css('top', stickTopPosition + "px");
                $('#btn-bar-placeholder').height($('#js-btn-bar').outerHeight() + "px");
            } else {
                $('#js-btn-bar').removeClass("stick-to-top").css('top', '0');
            }
        }
    }

    function showOverlay() {
        var popup = '#get-in-touch-popup';
        $(window).click(function() {
            if($(this.event.target).parents(popup + " .popup").length < 1 && !$(popup).hasClass("hidden") && !$(this.event.target).is(popup + " .popup")) {
                $(popup).addClass("hidden");
            }
            if($(this.event.target).is('.js-open-contact-form')) {
                $(popup).removeClass("hidden");
            }
        });
    }

    function formSubmit() {
        $('.popup-form form').submit(function (e) {
            e.preventDefault();
            var FormSerialize = $(this).serialize();
            $.ajax({
                url: $(this).data('url'),
                data: FormSerialize,
                cache: false,
                dataTypr: 'HTML',
                method: 'POST',
                success: function (data)
                {
                    $('.response-container').html('<p>' + $(data).html() + '</p>');
                }
            });

        });
    }
        
    return {
        init : init,
        stickyBtnBar : stickyBtnBar,
        showOverlay : showOverlay
    };

}();
