'use strict';
var dandryer = dandryer || {};

dandryer.search = dandryer.search || function () {

    function init(){
        searchDialog();
    }

    function searchDialog(){
        if ($('#js-search-dialog').length > 0) {

            $('#js-search-dialog-form').on('click', function(event) {
                event.stopPropagation();
            });

            $('#js-site-search-trigger, #js-search-dialog, #js-site-search-mobile-trigger').on('click', function(){
                if (!$('#js-search-dialog').hasClass('js-active')) {
                    $('#js-search-dialog').addClass('js-active');
                    $('body').addClass('js-search-open');
                    $('#search').focus();
                }
                else {
                    $('#js-search-dialog').removeClass('js-active');
                    $('body').removeClass('js-search-open');
                }
            });

        }
    }

    return {
        init : init,
        searchDialog : searchDialog
    };

}();
