var MiniCartPageId = 0; /* The hidden cart json page */
var MiniCartPageUrl = "";
function MiniCartInit(miniCartPageId, miniCartPageUrl) {
    MiniCartPageId = miniCartPageId;
    MiniCartPageUrl = miniCartPageUrl;
}

// Product detail page AddToCart function
function AddToCart(e, productid, variantId, quantity) {
    e.preventDefault();
    $.ajax({
        url: MiniCartPageUrl + '?productid=' + productid + '&variantid=' + variantId + '&Quantity=' + quantity + '&cartcmd=add',
        success: function(data) {
            $.ajax({
                url: '/da-dk/mini-cart-page',
                dataType: 'html',
                success: function(htmlSource) {
                    
                    $('.mini-cart__circle').html($(htmlSource).find('.mini-cart__circle').text());
                } 
            })
        },cache:false
    })
}; 

// Don't know if this is used
function EmptyCart() {
    $('#minipagecart').html("<i class=\"fa fa-shopping-cart\"></i><span class=\"amount\"></span>");

    UpdateCart("Default.aspx?ID=" + MiniCartPageId + "&cartcmd=emptycart");

    location.reload(true); /* Secures that this also makes sense in the checkout flow */
}

