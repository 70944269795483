'use strict';
var dandryer = dandryer || {};

dandryer.navigation = dandryer.navigation || function () {

    function init() {
        toggleMobileNavigation();
        subNavigation();
        toggleLanguagePicker();
        toTop();
    }

    function toggleMobileNavigation(){
        if ($('#js-main-navigation').length > 0) {
            $('#js-mobile-navigation-trigger, #js-mobile-navigation-close').on('click', function(){
                if (!$('#js-main-navigation').hasClass('js-active')) {
                    $('#js-main-navigation').addClass('js-active');
                    $('body').addClass('mobile-nav-open');
                }
                else {
                    $('#js-main-navigation').removeClass('js-active');
                    $('body').removeClass('mobile-nav-open');
                }
            });
        }
    }

    function toggleLanguagePicker(){
        if ($('#js-main-navigation').length > 0) {
            $('#js-language-picker-trigger').on('click', function(){
                if (!$('#js-language-picker').hasClass('js-active')) {
                    $('#js-language-picker').addClass('js-active');
                }
                else {
                    $('#js-language-picker').removeClass('js-active');
                }
            });
        }
    }

    function subNavigation(){
        if( $('#js-main-navigation').length) {
            var subToggle = $('.js-sub-navigation-trigger');
            subToggle.on('click', function(e){
                e.preventDefault();
                e.stopPropagation();
                $(this).parent().siblings('ul').slideToggle(100);
            });
        }
    }

    function stickyHeader(){
        if ($(window).scrollTop() > 1){
            $('#js-site-header').addClass("sticky");
        }
        else{
            $('#js-site-header').removeClass("sticky");
        }
    }

    function toTop(){
        var toTop = $('#totop');
        toTop.click(function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });
    }

    function toTopToggle(){
        var toTop = $('#totop');
        if ($(window).scrollTop() > 100) {
            toTop.fadeIn();
        } else {
            toTop.fadeOut();
        }
    }

    return {
        init : init,
        toggleMobileNavigation : toggleMobileNavigation,
        subNavigation : subNavigation,
        stickyHeader : stickyHeader,
        toggleLanguagePicker : toggleLanguagePicker,
        toTop : toTop,
        toTopToggle : toTopToggle
    };

}();
