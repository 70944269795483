'use strict';
var dandryer = dandryer || {};

dandryer.Iframe = dandryer.Iframe || function () {

    function init() {
        fancyBoxIframe();
    }



    function fancyBoxIframe() {
        $(".youtube-iframe").fancybox({
            maxWidth: 800,
            maxHeight: 600,
            fitToView: false,
            width: '70%',
            height: '70%',
            autoSize: false,
            closeClick: false,
            openEffect: 'none',
            closeEffect: 'none'
        });
    }

    return {
        init: init,
        changeDeliveryMethod: fancyBoxIframe

    };

}();
