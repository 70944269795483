'use strict';
var dandryer = dandryer || {};

dandryer.product = dandryer.product || function () {

    function init() {
        selectVariant();
        popup();
    }



    function selectVariant() {
        //if (sessionStorage.getItem('selectedVariant')) {
        //    var selectedVariant = sessionStorage.getItem('selectedVariant');
        //    $("#variant-list").val(selectedVariant);
        //}

        //$("#variant-list").change(function () {
        //    sessionStorage.setItem('selectedVariant', $(this).val());
        //});
    }

    function popup() {
        var $slider = $('.featured-image .owl-stage');
        $slider.magnificPopup({
            delegate: '.owl-item a', // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: { enabled: true }
        });
    }

    return {
        init: init,
        selectVariant: selectVariant,
        popup: popup
    };

}();
