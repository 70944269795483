'use strict';
var dandryer = dandryer || {};

dandryer.cart = dandryer.cart || function () {

    function init() {
        changeDeliveryMethod();
    }



    function changeDeliveryMethod() {
//

    }

    return {
        init: init,
        changeDeliveryMethod: changeDeliveryMethod

    };

}();
