'use strict';
/*
* Novicell master
*  Author: APN modified by DLA
*  Use this file to call functions in other files, on document ready, window.resize etc.
*  ------
* Dependencies: jquery, novicell.js, other files with awesome functions.
*  Usage: $(function () {
*              projectName.slider.heroSlider($('.owl-carousel'));
*         }); // Document ready end
*/

var novicell = novicell || {};
var dandryer = dandryer || {};

// Document ready
$(function () {

    novicell.cookieInfo.init();
    dandryer.navigation.init();
    dandryer.search.init();
    dandryer.cart.init();
    dandryer.product.init();
    dandryer.Iframe.init();
    dandryer.btnBar.init();

    svg4everybody(); // Fix SVG spritemap in IE/Edge

    novicell.font.webfont({
        google: { families: ['Roboto:100,300,400:latin'] }
    });

}); // Document ready end


/*
*  Use the following if needed
*/

// Window load
// $(window).load(function(e){
//     // call functions here
// }); // Window load

// Window resize (debounced)
$(window).smartresize(function(e){
    // novicell.responsiveLazyloadImage.onResize();
    // novicell.embed.onResize();
}); // Window resize

// Window scroll (debounced)
$(window).smartscroll(function (e) {


}); // Window scroll

$(window).scroll(function() {

    dandryer.navigation.stickyHeader();
    dandryer.navigation.toTopToggle();
    dandryer.btnBar.stickyBtnBar();

}); // Window scroll
